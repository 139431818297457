<template>
    <div>
        
        <div class="d-flex">
            <div style="width: 272px;">
                <left-side-bar />
            </div>
            <div style="padding: 0 32px; width: calc(100% - 372px);">
                <router-view />
            </div>
        </div>
        
<!--        <template v-if="tickets.listOf.length === 0">
            <div class="d-flex flex-column justify-content-center align-items-center w-100" style="height: calc(100vh - 224px);">
                <p class="text-muted text-center">
                    У Вас нет созданных тикетов.
                </p>
                <b-button pill variant="primary">Задать вопрос</b-button>
            </div>
        </template>
        -->
        
    </div>
</template>

<script>
    
    import tickets from "@/modules/tickets"
    import LeftSideBar from "./widgets/LeftSideBar"

    export default {

        data() {
            return {
                tickets
            }
        },
        methods: {

        },
        components: {
            LeftSideBar
        },
        watch: {

        },
        mounted() {

        }

    }

</script>
<template>
    <div>
        <showAt breakpoint="large">
            <desktop />
        </showAt>
        <showAt breakpoint="medium">
            <tablet />
        </showAt>
        <showAt breakpoint="mobile">
            <mobile />
        </showAt>
    </div>
</template>

<script>
    
    import desktop from "./views/desktop"
    import tablet from "./views/tablet"
    import mobile from "./views/mobile"

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {
            desktop,
            tablet,
            mobile
        },
        watch: {

        },
        mounted() {

        }

    }

</script>
<template>
    
    
    <b-card no-body >
        
        <b-card-body style="padding: 16px;">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              pill
              :to="{ name: 'user-tickets-compose' }"
              @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
            >
              Новый тикет
            </b-button>
        </b-card-body>
        
    </b-card>
    
<!--  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
            >
              Compose
            </b-button>
          </div>
          
        </div>
      </div>
    </div>
  </div>-->
</template>

<script>


export default {
  data() {
      return {
          filters: [
              { title: "Открытые" },
              { title: "Закрытые" },
              { title: "Архив" },
          ]
      }
  },
  components: {

  },
  props: {
    
  },

}
</script>

<style>

</style>
